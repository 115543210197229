.progress-toggle-button {
  width: 78px;
  height: 21.22px;
  left: 113.61px;
  top: 28.77px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #595f69;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 10.3469px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #595f69;
  cursor: pointer;
  &:hover {
    background-color: #0c121c;
    color: #f7f9fc;
  }
  &.open {
    background-color: #0c121c;
    color: #f7f9fc;
  }
  img {
    margin-left: 3.5px;
  }
}

.student-progress-graph {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 200px;
  width: 100%;
}

.progress-expanded {
  min-height: 200px;
}

.student-progress-chart {
  padding: 20px;
}

.tooltip-header {
  font-size: 15px;
  color: #3f434a;
}
.tooltip-footer {
  font-size: 14px;
  color: #8a9099;
}

.progress-info-tooltip {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: center;
  .progress-image-wrapper {
    position: relative;
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    z-index: 1;
  }
  img {
    z-index: 1000;
    width: 30%;
    height: 30%;
  }
  .progress-info-legend {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    p {
      margin: 0;
      font-size: 18px;
    }
    span {
      font-size: 14px;
      color: #8a9099;
    }
  }
}
.students-progress-header {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  .legend {
    font-weight: 500;
    font-size: 30px;
  }
}
.students-progress-info {
  padding: 20px;
  overflow-x: scroll;
}
