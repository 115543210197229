.schedule-wrapper {
  position: relative;
  display: flex;
  border: 2px solid rosybrown;
  flex-direction: row;
  min-width: 600px;
  .schedule-day {
    min-height: 50px;
    max-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid lightgrey;
  }
  .schedule-row {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    height: 75vh;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
  }
  .schedule-legend {
    width: 100px;
    border-right: 1px solid lightgrey;

    .mark-time-hour {
      border-top: 1px solid #bcbcbc;
    }
  }
  .schedule-time-mark {
    text-align: end;
    font-weight: bold;
    height: 2.5%;
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: end;
    align-items: self-end;
    line-height: 1;
    padding-right: 2px;
  }
  .schedule-data {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    .schedule-row {
      height: 75vh;
      position: relative;
      width: 20%;
      align-items: center;
      font-size: 25px;
      justify-content: start;
    }
    .schedule-day-items {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .schedule-day-item {
      cursor: pointer;
      padding: 0 3%;
      min-width: 96%;
      max-width: 96%;
      margin: 1% 2%;
      position: absolute;
      border-radius: 5px;
      background-color: #7fc2ff;
      font-size: 16px;
      color: white;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      border: 3px solid rgba(95, 95, 95, 0.31);
      line-height: 1;
      &:hover {
        height: unset !important;
        max-height: unset !important;
      }
    }
  }
  .schedule-legend-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.calendar_default_rowheader_inner:after {
  background-color: red;
}

.box {
  position: relative;
  background: #ccc;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
}
