// Here you can add other styles
.form-check-input:checked,
.form-check-label::before {
  background-color: #8bc4f0 !important;
  border-color: #8bc4f0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.form-check-input:focus,
.form-check-label::before {
  box-shadow: #8bc4f0 0 0 0 1px inset !important;
}

.portal {
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 9999;
  color: #454545;
  font-size: 15px;
  font-weight: bold;
  .cross-container {
    word-break: break-all;
    border: 3px solid #161616;
    padding: 40px 20px 20px;
    width: 100%;
    height: 100%;
    position: relative;
    hr {
      margin: 0;
    }
    .cross {
      cursor: pointer;
      position: absolute;
      top: 2px;
      right: 10px;
    }
  }
}

// form check border color to be fixed
// .form-check-input::after,
// .form-check-input::before {
//   border-color: #8bc4f0 !important;
// }

.sidebar {
  background-color: #1d2553 !important;
}

.ql-editor {
  min-height: 200px;
}

.mastery-btn {
  background-color: 'red' !important;
  border-color: 'yellow' !important;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}
.edit-tag {
  user-select: none;
}
.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
}

.custom-objective {
  white-space: normal;
}

.add-goals-wrapper {
  .table-responsive {
    max-height: 50vh;
  }
  .objective-cell {
    .controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      img {
        height: 30px;
        &:hover {
          height: 32px;
        }
      }
    }
  }
  .goal-head-wrapper {
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    button {
      min-width: 35px;
      min-height: 35px;
      max-width: 35px;
      max-height: 35px;
    }
    .goal-text {
      padding: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .goal-controls {
      display: none;
      margin-top: 10px;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      align-items: center;
    }
    &:hover {
      .goal-controls {
        display: flex;
      }
      .goal-text {
        white-space: normal;
      }
    }
    img {
      cursor: pointer;
      height: 30px;
      &:hover {
        height: 32px;
      }
    }
    button {
      line-height: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .athena-input-wrapper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 20px;
    top: 140px;
  }
  .athena-input {
    width: 220px;
  }
  .buttons-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    min-height: 60px;
    padding-right: 15px;
    flex-wrap: nowrap;
    button {
      max-height: 40px;
      margin-left: 20px;
    }
  }
  td {
    max-width: 200px !important;
    max-height: 50px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img {
      cursor: pointer;
      width: 30px;
      display: none;
    }
  }
  td:hover {
    img {
      display: block;
    }
    max-height: unset !important;
    white-space: unset !important;
    overflow: unset !important;
    text-overflow: unset !important;
  }
}

.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 0;
  z-index: 100;
}

.student-tracker-table {
  .anticon-check-circle {
    svg {
      color: rgb(181, 123, 255);
    }
  }
  th {
    max-width: 200px;
  }
}

.template-download {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  text-decoration: none;
  left: 0;
  top: 0;
}

.custom-button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #ffffff;
  border: 1px solid #595f69;
  border-radius: 15px;
  padding: 7px 40px;
  font-size: 1rem;
  font-weight: 500;
  span {
    color: #595f69;
  }
  img {
    position: absolute;
    top: 11px;
    right: 12px;
    margin-left: 12px;
    height: 18px;
  }
  &:hover {
    img {
      top: 10px;
    }
    border: 2px solid #595f69;
  }
}

.actions-section {
  .dropdown-menu {
    left: -175px !important;
  }
  .dropdown-item {
    img {
      width: 16px;
      height: 16px;
      margin-right: 16px;
    }
    min-width: 224px;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #595f69;
    padding: 4px 28px;
    display: flex;
    flex-direction: row;
  }
}

.smart-table-custom-wrapper {
  input {
    border: none !important;
  }
  th>input {
    border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
  }
  .search-icon {
    position: absolute;
    left: 10px;
    top: 115px;
    height: 32px;
    width: 32px;
  }
  .teacher-status-dropdown {
    max-width: 200px;
    .teacher-status-wrapper {
      cursor: pointer;
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 25px;
      &.selectable {
        margin-top: 5px;
        border-radius: 3px;
        &:hover {
          background-color: rgba(82, 82, 82, 0.41);
        }
      }
      .teacher-status-indicator {
        min-width: 14px;
        min-height: 14px;
        border-radius: 100%;
      }
    }

    .first-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 25px;
      min-width: 70px;
    }
  }
  &.student-groups-management, &.teacher-management {
    th {
      .form-check-input:first-child {
        border: 1px solid black !important;
      }
    }
    tr {
      td:last-child {
        text-align: end;
        padding-right: 10px;
      }
    }
  }
}

.task-details {
  .dropdown-menu {
    width: 975px;
    left: -610px !important;
  }
}

.task-management {
  .dropdown-menu-custom {
    left: -150px !important;
    width: 615px;
  }
  .search-icon {
    position: absolute;
    top: 3px;
    left: 15px;
    height: 30px;
    width: 30px;
    z-index: 1;
  }
  .header-input {
    width: 200px;
    z-index: 2;
    padding-left: 40px;
    background: unset;
  }
}

.teacher-edit-wrapper {
  .teacher-edit-body {
    padding: 36px;
    display: flex;
    flex-direction: row;
    .menu {
      position: relative;
      min-width: 297px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .item {
        cursor: pointer;
        width: 90%;
        padding: 5px 15px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 21px;
        line-height: 30px;
        &.active {
          color: #3e66fb;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3e66fb;
          border-radius: 12px;
        }
      }
    }
    .content {
      width: 100%;
      .title {
        color: #1d2553;
        font-size: 24px;
        line-height: 36px;
      }
      .subtitle {
        color: #1d2553;
        font-size: 18px;
        line-height: 24px;
      }
      input.custom {
        margin-top: 12px;
        height: 60px;
        font-size: 21px;
        line-height: 30px;
        color: #1d2553;
        border-radius: 15px;
      }
      .fake-input {
        position: relative;
        margin-top: 12px;
        background: #ffffff;
        border: 1px solid var(--cui-input-border-color, #b1b7c1);
        border-radius: 15px;
        min-height: 60px;
        cursor: text;
        padding: 8.5px 100px 8.5px 17px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16.5px;
        flex-wrap: wrap;
        .selected-input-item {
          font-size: 14px;
          line-height: 20px;
          color: #1d2553;
          padding: 7px 17px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          img {
            width: 24.5px;
            height: 24.5px;
            cursor: pointer;
            &:hover {
              width: 22.5px;
              height: 22.5px;
              margin: 1px;
            }
          }
        }

        .add-badge {
          position: absolute !important;
          right: -65px;
          top: 10px;
          button:nth-child(1) {
            border-radius: 15px 0 0 15px;
            padding: 5px;
            max-width: 50px;
          }
          .dropdown-toggle {
            border-radius: 0 15px 15px 0;
            padding: 5px;
            max-width: 30px;
          }
        }
      }
      .avatar-section {
        width: 100%;
        position: relative;
        margin-top: 50px;
        .legend {
          margin-top: 12px;
          color: rgba(0, 0, 0, 0.5);
        }
        .clickable-text {
          color: #3e66fb;
          cursor: pointer;
        }
        .image {
          position: absolute;
          right: 0;
          top: 0;
          border-radius: 100%;
          background-position: center;
          background-size: cover;
          width: 84px;
          height: 84px;
        }
      }

      .name-section {
        margin-top: 33px;
      }

      .subjects-section,
      .email-section {
        margin-top: 28px;
      }
      .submit-footer {
        position: relative;
        min-height: 150px;
        .submit-button {
          position: absolute;
          right: 38px;
          bottom: 20px;
        }
      }
    }
  }
}

.add-objectives-wrapper {
  .card-header {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end !important;
    border-bottom: none !important;
    background-color: white !important;
  }
  .dropdowns-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .select-subject-dropdown {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    .selected-subject {
      max-width: 95%;
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subject-dropdown-item {
      padding: 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .subject-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        .subject-text {
          white-space: break-spaces;
        }
        img {
          margin-right: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .select-subject-dropdown,
  .select-year-group-dropdown {
    .dropdown-items {
      max-height: 300px;
      max-width: 220px;
      overflow-y: scroll;
    }
    padding: 0;
    color: #3f434a;
    min-width: 220px;
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
      border-radius: 15px 0 0 15px !important;
      border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
      padding: 0 10px !important;
      max-width: unset !important;
      background-color: white !important;
      width: 100%;
      height: 100%;
    }
  }
  .select-year-group-dropdown {
    .group-name {
      white-space: break-spaces;
      display: flex;
      align-items: center;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
    }
    li {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      position: relative;
      img {
        position: absolute;
        left: 10px;
      }
      span {
        margin-left: 40px;
      }
    }
    .dropdown-toggle {
      border-radius: 0 15px 15px 0 !important;
    }
  }
  .subject-search-input {
    width: 90%;
    max-height: 40px;
    background-color: #f8f8f8;
    margin: 0 !important;
    border: none !important;
    font-size: 14px !important;
    line-height: 21px !important;
    display: flex;
    align-items: center;
    color: #8a9099;
    margin-bottom: 15px !important;
  }
}

.custom-selectable-button {
  position: relative;
  padding: 6px 8px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  img {
    position: absolute;
    left: 26px;
  }
  &.add-student {
    display: flex;
    align-items: center;
    padding-left: 58px;
    border-radius: 10px;
    width: 100%;
    min-width: 130px;
  }
}

.page-controls-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 30px;
  min-height: 100px;
}

.attendance-calendar {
  .calendar-cell.selected{
    border: 3px solid #f9d4d4 !important;
    .calendar-cell-inner {
      background-color: grey !important;
    }
  }
}

.attendance-blue {
  padding: 0 2px;
  .calendar-cell-inner {
    background-color: #7fc2ff !important;
  }
  &.today {
    .calendar-cell-inner {
      font-weight: 600;
    }
  }
}

.attendance-yellow {
  padding: 0 2px;
  .calendar-cell-inner {
    background-color: #f4a032 !important;
  }
  &.today {
    .calendar-cell-inner {
      font-weight: 600;
    }
  }
}

.attendance-red {
  padding: 0 2px;
  .calendar-cell-inner {
    background-color: #ff3b2a !important;
    color: white !important;
    &.selected {
      background-color: #ff3b2a !important;
      color: white !important;
    }
  }
  &.today {
    .calendar-cell-inner {
      font-weight: 600;
    }
  }
}

.subject-advanced-dropdown {
  .selected-subject-advanced {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 30px;
    display: inline;
    flex-direction: row;
  }
  .btn {
    background-color: white !important;
    border: 1px solid var(--cui-form-select-border-color, #b1b7c1) !important;
    box-shadow: none !important;
  }
  .btn:hover {
    background-color: #aea3a3 !important;
    border: 1px solid var(--cui-form-select-border-color, #b1b7c1) !important;
    box-shadow: none !important;
  }
  .no-border-button {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 17px;
  }
  .dropdown-toggle {
    align-items: center;
    justify-content: space-between;
    display: flex;
  }
  .vide-version {
    .dropdown-toggle {
      align-items: center;
      justify-content: space-between;
      display: flex;
    }
  }
}

.dropdown-hint {
  min-width: 50px;
  min-height: 38px;
  border: 1px solid var(--cui-form-select-border-color, #b1b7c1) !important;
}

.admissions-list-wrapper, .students-list-wrapper {
  position: relative;
  .add-new-student-btn {
    position: absolute;
    top: 80px;
    right: 30px;
  }
  .switch-archive-btn {
    position: absolute;
    top: 80px;
    right: 220px;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: end;
  span:nth-child(2){
    color: gray;
  }
}

.reports-generator-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  gap: 10px;
  align-items: flex-end;

  .generate-report-controls {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;
    justify-content: flex-end;
    align-items: end;
    img {
      width: 50px;
      height: 50px;
      cursor: pointer;
      &:hover {
        width: 52px;
        height: 52px;
      }
    }
  }
}

.text-field-wrapper {
  position: relative;
  .count {
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
  .ql-editor {
    padding-bottom: 25px;
  }
}

.alert-wrapper {
  position: relative;
  .alert-image {
    width: 30px;
    height: 30px;
  }
  .alert-message {
    border: 1px solid orange;
    padding: 7px 12px;
    position: absolute;
    left: 40px;
    top: -20px;
    background-color: white;
    color: #0c121c;
    max-height: 60px;
    width: 300px;
  }
}

.fake-dropdown {
  max-width: 110px;
  background-color: white;
  &:after {
    display: none;
  }
}

.attendance-graph-container {
  @media only screen and (max-width: 1599px) {
    min-width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    background-color: white;
    padding-bottom: 20px;
  }
}

.attainment-overview-container {
  @media only screen and (max-width: 1599px) {
    min-width: 100%;
  }
}

.task-filter-wrapper, .dashboard-wrapper {

  .multi-select {
    position: relative;
    width: 100%;
    .form-multi-select {
      z-index: 2;
      background-image: none !important;
      background-color: unset !important;
      padding-right: 60px !important;
    }
    .search-icon-multi-select {
      position: absolute;
      right: 4px;
      top: 47%;
      opacity: 0.5;
      z-index: 1;
    }
  }
}

.task-filter-wrapper {
  padding: 33px 37px 67px 51px;
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-top: -10px;
  }
  .reset-button {
    color: #3E66FB;
    text-decoration: underline;
    cursor: pointer;
  }
  .footer-buttons {
    button {
      width: 135px !important;
    }
  }
}

.task-management {
  .task-section-divider {
    height: 3.5px;
    width: 100%;
  }
  .task-section-wrapper {
    width: 100%;
    max-width: 450px;
  }
  .task-cards-wrapper {
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    gap: 38px;
    &.empty {
      div:first-child {
        min-height: 600px;
      }
    }
  }
  .task-section-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    div:first-child {
      min-height: 62px;
    }
  }
  .task-section-head-text {
    font-weight: 600;
    font-size: 16px;
  }
  .task-card {
    position: relative;
    background-color: white;
    padding: 31px;
    display: flex;
    flex-direction: column;
    gap: 21px;
    border: 1px solid lightgrey;
    box-shadow: 0px 0px 1.29658px rgba(12, 26, 75, 0.24), 0px 3.88974px 10.3726px -1.29658px rgba(50, 50, 71, 0.05);
    border-radius: 20.7453px;
    margin-bottom: 30px;
    .task-select {
      position: absolute;
      top: 5px;
      left: 9px;
      input {
        border: 1px solid gray !important;
      }
    }
    .task-card-header {
      display: flex;
      justify-content: space-between;
      .task-card-header-text {
        font-size: 21px;
        font-weight: 600;
        line-height: 32.41px;
      }
    }
    .task-info {
      div {
        font-weight: 600;
      }
      line-height: 30px;
      font-size: 18px;
    }
    .task-badges-section {
      display: flex;
      gap: -15px;
      .task-badge {
        width: 47px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: 2.59316px solid #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        color: white;
        margin-right: -15px;
        &:hover {
          z-index: 15 !important;
        }
      }
    }
  }
}

.white-button {
  background-color: white !important;
  color: #595F69 !important;
  border: 1px solid #E8E9EB !important;
}

.spinning-button {
  cursor: pointer;
  &:hover {
    transform: rotate( -180deg );
    transition: transform 150ms ease;
  }
}

.plus-button {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #505780;
  background-color: #E4ECF7;
  border: none !important;
  box-shadow: none !important;
  font-size: 20px;
}

.share-button {
  display: flex;
  color: white !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  svg {
    path {
      fill: white;
    }
  }
}

.task-details {
  .form-label {
    color: #8A9099;
    font-size: 14px;
  }
  .plus-button {
    margin-left: 30px;
    border-radius: 12px;
    height: 28px;
    width: 28px;
    padding-bottom: 10px;
  }
  .task-details-actions-section-header {
    display: flex;
    font-size: 14px;
    align-items: center;
    width: 90%;
  }
  .task-details-actions-section {
    min-width: 380px;
  }
  .recorded-date-wrapper {
    padding: 11px 17px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    border: 1px solid #E8E9EB;
    border-radius: 15px;
    justify-content: center;
    width: 227px;
    margin-bottom: 34px;
  }
  .task-details-student-target {
    padding: 5px 18px;
    border-radius: 10px;
    height: 100%;
    width: 75%;
  }
  .footer-buttons {
    margin-top: 50px;
  }
  .slides-head-controls {
    max-height: 40px;
  }
  .slide-controls {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    input {
      text-align: center;
      margin: 0!important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    svg {
      &:hover {
        height: 16px;
        margin-bottom: -1px;
      }
    }
    input {
      font-weight: bold;
      &:focus {
        border: none !important;
        transition: none !important;
        box-shadow: none !important;
      }
    }
  }
}

.task-details-preview-portal {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: absolute;
  width: 100%;
  background-color: white;
  top: 0;
  z-index: 99999;
  min-height: 105vh;
  .task-iframe {
    min-height: calc(100vh - 38px) !important;
  }
}

.task-details-preview-portal-small {
  .header-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 60px;
  }
  .open-full-button {
    position: absolute;
    margin: 0 !important;
    left: 15px;
  }
}

.task-iframe {
  height: calc(100vh - 300px);
}

.react-google-slides-wrapper {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dropdown-vertical-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.delete-icon-wrapper {
  color: #fd7972;
}

.goal-objective-template {
  iframe {
    min-height: 85vh;
  }
}


.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  .dropdown-menu {
    left: -175px !important;
  }
  .dropdown-item {
    img {
      width: 16px;
      height: 16px;
      margin-right: 16px;
    }
    min-width: 224px;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #595f69;
    padding: 4px 28px;
    display: flex;
    flex-direction: row;
    &.disabled {
      color: #bfbfbf;
      img {
        opacity: 0.5;
      }
    }
  }
}

.custom-icon-button {
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    &:hover {
      left: -5%;
      top: -5%;
      min-width: 110%;
      min-height: 110%;
    }
  }
}

.assigment-controls {
  img:nth-child(2) {
    cursor: not-allowed;
  }
}

.font-weight-bold {
  font-weight: 600;
}

.attainment-legend {
  font-size: 12px;
  line-height: 12px;
  .attainment-legend-block {
    max-width: 125px;
    min-width: 125px;
  }
}

.card-footer {
  .text-medium-emphasis {
    min-height: 44px;
  }
}

.table-search-custom {
  margin-bottom: 30px;
  width: 150px;
}

.button-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no-annoying-spinner {
  top: -50px;
  left: -50px;
  z-index: 99;
  background-color: #ffffff91;
  height: 500px;
  width: 110%;
  position: absolute;
}

.year-group {
  padding: 13px !important;
}

.candidates-list-container {
  .s-table-wrapper {
    max-height: 65vh;
    overflow: scroll;
    th>div{
      min-height: 45px;
      height: 100%;
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
    }
    th {
      position: relative;
      span {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}


.fixed-header-custom {
  position: absolute;
  display: flex;
  width: 70%;
  top: 55px;
  right: 0;
  justify-content: end;
  gap: 50px;
}

.late-registrations-list-container {
  .time {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    text-align: center;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .teachers-dropdown {
    min-width: 50%;
    max-width: 70%;
  }
  tr {
    cursor: pointer;
  }
}

